/* eslint-disable no-console */

import { Workbox } from "workbox-window";

let wb;

if (process.env.NODE_ENV !== 'development' && "serviceWorker" in navigator) {
  console.log('service worker...');
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.addEventListener("updated", (registration) => {
    console.log('New version is available; please refresh.');
    document.dispatchEvent(
      new CustomEvent('swUpdated', { detail: registration })
    );
  });

  wb.addEventListener('activated', (event) => {
    console.log('New version installed and running.');
  });

  wb.addEventListener('message', (event) => {
    if (event.data && event.data.type === 'SKIP_WAITING') {
      console.log('skip waiting');
      skipWaiting();
    }
  });

  wb.register();
} else {
  console.log(process.env.NODE_ENV, 'service worker –');
  wb = null;
}

export default wb;
