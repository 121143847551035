// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  data: {
    selected: null,
    filter: {
      status: null,
      search: null,
    },
    items: {},
  },
  
  prototype: {
    id: null, 
    code: null, 
    status: null, 
    standby: 'STB_OFF',
    away: false,
    mode: null, 
    diagnotics: "OFF",
    up_time: null,
    control_number: null,
    position: {
      lat: null,
      lng: null,
    },
    updated: null,
    impression: {
      position: {
        lat: null,
        lng: null,
      },
      audience: null,
      updated: null,
      file: null,
    },
    driver: {
      cpf: null, 
      name: null, 
      photo: null
    },
    network: {
      ssid: null,
      password: null,
      ip_local: null,
      ip_publico: null
    },
    controller: {
      mcu: null,
      os: null,
      db: null,
      app: null,
      ram_tot: null,
      disk_tot: null,
    },
    other: {
      tk_acesso: null,
      cod_acesso: null,
      code_version_owner: null,
      code_version_main: null,
    },
    warnings: {
      code: null,
      messages: [],
      notifiedAt: null
    },
    display: {
      rgb_solution: null,
      chipset: null,
      pixel_pitch: null,
    },
    selected: false,
    loading: false,
  },
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
