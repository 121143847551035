<template>
  <v-fade-transition mode="out-in">
    <router-view
      :updating="refreshing"
      :admin="admin"
      @reset="onReset"
    />
  </v-fade-transition>
</template>

<style>
  :root {
    --mobees-black: #26272C;
    --driver-green-500: #68DECE;
    --driver-green-400: rgba(69, 217, 207, 1);
    --driver-green-300: rgba(116, 231, 219, 1);
    --driver-green-200: rgba(168, 240, 235, 1);
    --driver-green-100: rgba(211, 248, 247, 1);
    --driver-green-50: rgba(238, 251, 252, 1);
    --driver-green-600: rgba(59, 182, 186, 1);
    --driver-green-700: rgba(53, 138, 151, 1);
    --driver-green-800: rgba(47, 102, 116, 1);
    --driver-green-900: rgba(39, 76, 83, 1);
    --city-blue-400: #698DF2;
    --primary: var(--city-blue-400);
    --success: #45D9CF;
    /* --city-blue-A100: #D3F8F3; */
    --city-blue-A400: #5A81ED;
    --accent: var(--city-blue-A400);
    --city-blue-50: #F7F9FC;
    /* --city-blue-A700: #1F788E;
    --city-blue-300: #63E3D2;
    --city-blue-800: #286367;
    --city-blue-900: #274C53; */
    --white-hover-overlay: rgba(255,255,255,.08);
    --inset-2: inset 0px 3px 1px -2px rgba(0, 0, 0, .2), inset 0px 2px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    --light-border: rgba(0,0,0,.12);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  html, body, #app.v-application {
    background: var(--v-soft-base);
    color: var(--mobees-black);
  }
  @media (min-width: 600px) {
    html, body, #app.v-application {
      /* background: var(--mobees-black); */
    }
  }

  #app .theme--light.v-sheet {
    color: var(--mobees-black);
  }


  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }

  .secondary-type {
    font-family: 'Varela Round', 'Noto Sans', Helvetica, Arial, sans-serif !important;
  }
  
  a {
    text-decoration: none;
  }
  i {
    font-style: normal;
  }
  em {
    font-style: normal;
    font-weight: 500;
  }

  .fill-width {
    width: 100%;
  }

  .scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .scrollable.x {
    overflow-y: auto;
    overflow-x: scroll;
  }
  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .snap-x {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 8px;
    scroll-margin: 8px;
  }
  .snap-child {
    scroll-snap-align: start;
  }

  #app .text-truncate-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
  #app .text-truncate-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
  }

  #app .text-untruncate {
    overflow: initial !important;
    text-overflow: initial !important;
    white-space: initial !important;
  }


  #app .v-dialog > .v-card, #app .v-dialog > .v-sheet {
    border-radius: .5rem !important;
  }
  #app .v-bottom-sheet > .v-sheet {
    border-radius: .5rem .5rem 0 0 !important;
  }
  #app .v-dialog:not(.v-dialog--fullscreen):not(.v-bottom-sheet) {
    max-width: 50vw;
  }
  #app .v-dialog.v-dialog--fullscreen {
    max-height: 100vh;
    top: auto;
  }

  #app .dense-list .v-list-item__action:first-child, #app .dense-list .v-list-item__icon:first-child {
    margin-right: 20px;
  }


  input:-internal-autofill-selected {
    background-color: rgba(232, 240, 254,0) !important;
  }

</style>

<script>

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  import services from '@/services.js'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'App',

    metaInfo () {
      return {
        title: 'Mobees',
        titleTemplate: '%s | COM',
        htmlAttrs: { lang: 'pt-br' },
        meta: [{ charset: 'utf-8' }],
      }
    },

    data () {
      return {
        refreshing: false,
        registration: null,
        updateExists: false,
        temp: {
          user: null,
          redirect: null,
        },
        admin: typeof process.env.VUE_APP_ADMIN != 'undefined' && eval(process.env.VUE_APP_ADMIN)
      };
    },

    computed: {
      user: sync('user/data'),
      updated: sync('app/updated'),
      redirect: sync('app/redirect'),
      toast: sync('app/toast'),
    },

    watch: {
      $route (to, from) {
        console.log('App... from', from.path, 'to', to.path);
        if (to.path != '/login'&&from.name!=null) this.redirect = to.path;
      },
    },

    methods: {
      ...services,

      notifyUpdate (e) {
        // if (this.user.auth.status) this.temp.user = this.user;
        // this.temp.redirect = this.redirect;
        // this.setStorage("temp", JSON.stringify(this.temp));


        this.toggleToast(
          true,
          'Nova versão do app disponível!',
          0,
          {
            toggle: true,
            text: 'Atualizar',
            action: this.refreshApp
          },
        )
      },

      async refreshApp () {
        console.log('refreshApp');
        this.updateExists = false;

        // this.temp.updated = true;
        // if (this.user.auth.status) this.temp.user = this.user;
        // this.delStorage('user');
        this.setStorage('updated', true);

        await this.$workbox.messageSW({ type: "SKIP_WAITING" });

        setTimeout(() => {
          // if (this.user.auth.status) this.user = Object.assign(this.user, this.temp.user);
          // this.redirect = this.temp.redirect;
          this.updated = true;
  
        //   if (!this.registration || !this.registration.waiting) { return; }
        //   this.registration.waiting.postMessage('skipWaiting');
        }, 500);
      },

      onReset () {
        console.log('onReset');
        this.toast.toggle = false;
        this.updateExists = false;
        this.refreshing = true;

        // if (this.user.auth.status) this.temp.user = this.user;
        // this.temp.redirect = this.redirect;

        // this.setStorage("temp", JSON.stringify(this.temp));

        setTimeout(() => {
          // this.delStorage('vuex', 'localForage');

          window.location.reload();
        },500);
      }
    },

    created () {
    },

    mounted () {
      this.getStorage('user').then((user) => {
        if (user===null||(user.auth.token==null&&this.$route.path!='/login')) {
          this.$router.push('/login');
        }
      });
      this.getStorage('updated').then((updated) => {
        if (updated===true) {
          // setTimeout(() => {
          //   this.toggleToast(
          //     true,
          //     'App atualizado!',
          //     3000,
          //     false
          //   )
          // }, 500);
          this.setStorage('updated', false);
        }
      });
      // let temp = this.getStorage("temp");
      // temp = typeof temp != 'undefined' ? JSON.parse(temp) : null;

      // if (temp!=null) {
      //   console.log(temp);
      //   this.delStorage("temp");
      //   // if (temp.redirect!=null) this.redirect = temp.redirect;
      //   if (temp.user!=null) user = this.setStorage(Object.assign(this.user, this.temp.user);
    // }
      
      
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.notifyUpdate();
        });
      }

      // window.addEventListener('resize', () => {
      //   // We execute the same script as before
      //   let vh = window.innerHeight * 0.01;
      //   document.documentElement.style.setProperty('--vh', `${vh}px`);
      // });

      this.cancelToasts();
    }
  }
</script>
