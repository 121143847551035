// Imports
import Vue from 'vue'
import Router from 'vue-router'
import vuetify from '@/plugins/vuetify'
import store from '@/store/index';
import VueGtag from "vue-gtag";

Vue.use(Router)

function layout (path, folder, name, children) {
  return {
    path,
    component: () => import(`@/layouts/${folder}/Index`),
    children,
  }
}

function redirect (path, redirect) {
  return { path: path, redirect }
}

function route (path, folder, name, children) {
  const route = {
    path,
    name,
    component: () => import(`@/views/${folder}/Index.vue`),
  }
  if (typeof children != 'undefined') route.children = children;
  return route;
}

function subroute (path, folder, name, file='Index') {
  return {
    path,
    name,
    component: () => import(`@/views/${folder}/${file}.vue`),
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = false;
    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition!=null) {
      scrollTo = savedPosition.y
    }

    return scrollTo ? vuetify.framework.goTo(scrollTo) : false;
  },
  routes: [
    subroute('/maintenance', 'login', 'Maintenance', 'Maintenance'),
    
    layout('/login', 'login', 'Login', [
      route('', 'login', 'Login'),
    ]),
    layout('/buzzers', 'home', 'Buzzers', [
      route(':buzzer', 'buzzers', 'Buzzer'),
      route('', 'buzzers', 'Buzzers'),
    ]),
    layout('/drivers', 'home', 'Motoristas', [
      route(':driver', 'drivers', 'Motorista'),
      route('', 'drivers', 'Motoristas'),
    ]),
    layout('/club', 'home', 'Clube', [
      route('', 'club', 'Clube'),
    ]),
    layout('/messaging', 'home', 'Comunicações', [
      route('', 'messaging', 'Comunicações'),
    ]),
    layout('/flashmobs', 'home', 'Flashmobs', [
      route('', 'flashmobs', 'Flashmobs', [
        subroute(':id', 'flashmobs', 'Flashmob'),
      ]),
    ]),
    layout('/support', 'home', 'Atendimento', [
      route(':queue?/:ticket?', 'support', 'Atendimento'),
    ]),

    redirect('*', { name: 'Buzzers' }),
  ],
})

router.beforeEach(async (to, from, next) => {
  await store.restored;
  const inMaintenance = process.env.VUE_APP_MNT==='true';
  console.log(inMaintenance);
  if (inMaintenance&&to.name!='Maintenance') {
    next({ name: 'Maintenance' })
  }else if (!inMaintenance&&to.name=='Maintenance') {
    next({ path: '/login' })
  }else{
    next()  
  }
})


// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-gtag

const toggle = process.env.NODE_ENV === 'production';

Vue.use(VueGtag, {
  enabled: toggle,
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  },
  pageTrackerTemplate (route) {
    console.log(route.meta.title||route.name)
    return {
      page: route.path,
      title: route.meta.title||route.name + ' | COM',
      location: window.location.href
    }
  },
  // debug: {
  //   // enabled: !toggle,
  //   sendHitTask: toggle,
  // },
}, router);

export default router
