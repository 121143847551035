import Vue from 'vue'
import axios from 'axios'

// const source = axios.CancelToken.source();

const COM = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API_COM,
  crossDomain: true,
})

const $api = { COM }

Vue.prototype.$api = $api

export default $api
