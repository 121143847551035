
export const driver = {
  'cpf': 'cpf_motorista',
  'partner': 'parceiro',
  'fullname': 'nm_motorista',
  'photo': 'foto_motorista',
  'birthdate': 'dt_nasc_motorista',
  'email': 'email_motorista',
  'phone': 'wpp_motorista',
  'rg.id': 'rg_motorista',
  'rg.issuer': 'org_exp_motorista',
  'cnh': 'cnh_motorista',
  'gender': 'genero',
  'status': 'status',
  'changedAt': 'dt_Status',
  'gps': 'gps',
  'push': 'notific',
  'app_version': 'vs_app',
  'os': 'vs_so',
  'rating': 'destaque',
  'tags': 'tags',
  'fleet': 'id_fleet',
  'job.products': 'products_interest',
  'job.special_projects': 'special_projects_interest',
  'job.apps': 'app_work',
  'job.apps_percent': 'app_work_percent',
  'job.service_model': 'mod_trab',
  'job.fulltime': 'nivel_env',
  'job.region': 'regiao_rodada',
  'job.rating': 'nota_app',
  'job.journey': 'nr_rodadas',
  'job.experience': 'tempo_motorista',
  'address.country': 'pais',
  'address.state': 'uf',
  'address.city': 'cidade',
  'address.zip': 'cep',
  'address.neighborhood': 'bairro',
  'address.street': 'logradouro',
  'address.number': 'numero',
  'address.compl': 'complemento',
  'vehicle.rental': 'nm_locadora',
  'vehicle.parking': 'estacionamento',
  'vehicle.plate': 'placa',
  'vehicle.chassis': 'chassi',
  'vehicle.brand': 'marca',
  'vehicle.model': 'modelo',
  'vehicle.year': 'ano_fab',
  'vehicle.color': 'cor',
  'vehicle.fuel': 'fuel_type',
  'vehicle.renavam': 'renavam',
  'vehicle.isOwner': 'dono',
  'vehicle.ownerName': 'nm_prop',
  'vehicle.ownerRg': 'rg_prop',
  'vehicle.ownerOrg_exp': 'org_exp_prop',
  'vehicle.ownerEmail': 'email_prop',
  'vehicle.ownerCpf': 'cpf_prop',
  'vehicle.ownerRelative': 'parent_prop',
  'vehicle.envelope_id': 'envelope_id',
  'payment.bank.id': 'id_banco',
  'payment.bank.code': 'cod_banco',
  'payment.bank.title': 'nm_banco',
  'payment.branch': 'agencia',
  'payment.account': 'conta',
  'payment.digit': 'dv',
  'payment.type': 'tipo_conta',
  'payment.compl': 'comp_conta',
}

export default {
  driver
}