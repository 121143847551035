// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  state: {
    filters: {
      status: 1
    },
  }
}

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
